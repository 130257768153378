import {Injectable} from '@angular/core';
import {OrganizationImage} from './organization-image.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { OrganizationLogoApiConstants } from './organization-logo-api.constant';

@Injectable({
  providedIn: 'root'
})
export class OrganizationLogoImageService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public getImage(organizationId: string): Observable<OrganizationImage[]> {
    return this.get<OrganizationImage[]>(OrganizationLogoApiConstants.get(organizationId))
            .pipe(
              map(results => results.map(result => new OrganizationImage(result)))
            );
  }

  public removeImage(organizationId: string, logoId: string): Observable<any> {
    return this.delete<any>(OrganizationLogoApiConstants.update(organizationId, logoId));
  }

  public uploadUri(organizationId: string): string {
    return OrganizationLogoApiConstants.get(organizationId);
  }

  public createUpdate(organizationId: string, image: OrganizationImage): Observable<OrganizationImage> {
    if (image.id) {
      return this.put<OrganizationImage>(OrganizationLogoApiConstants.update(organizationId, image.id), JSON.stringify(image))
              .pipe(
                map(result => new OrganizationImage(result))
              );
    }
    return this.post<OrganizationImage>(OrganizationLogoApiConstants.get(organizationId), JSON.stringify(image))
            .pipe(
              map(result => new OrganizationImage(result))
            );
  }
}
