import { environment } from '@environments/environment';

export class OrganizationLogoApiConstants {
  public static organizationUri = environment.serverUri + `api/${environment.apiVersion}/organizations`;

  public static get(organizationId: string) {
    return `${OrganizationLogoApiConstants.organizationUri}/${organizationId}/logos`;
  }
  public static update(organizationId: string, logoId: string) {
    return `${OrganizationLogoApiConstants.organizationUri}/${organizationId}/logos/${logoId}`;
  }
}
